.App {
  text-align: center;
  background-color: red;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container {
  width: 90%;
  max-width: 600px;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

h1, h2, h3 {
  margin-bottom: 20px;
}

input[type="text"] {
  width: 80%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  width: 40%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 5px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #45a049;
}

ul {
  list-style-type: none;
  margin-top: 10px;
}

li {
  margin: 5px 0;
  background-color: #eee;
  padding: 10px;
  border-radius: 5px;
}

.vote-section, .user-section, .votes-list {
  margin-top: 20px;
}

.vote-section input {
  width: 60%;
  margin-right: 10px;
}

.vote-section button {
  width: 30%;
}

/* Responsive Design */
@media (max-width: 600px) {
  .container {
      padding: 15px;
  }

  button {
      width: 100%;
      margin: 10px 0;
  }

  .vote-section input, .vote-section button {
      width: 100%;
  }
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.card {
  width: 125px;
  height: 150px;
  border: 2px solid #000;
  border-radius: 10px;
  background-color: white;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-family: Arial, sans-serif;
}

.corner-top, .corner-bottom {
  position: absolute;
  font-size: 36px;
  font-weight: bold;
  color: red;
}

.corner-top {
  top: 10px;
  left: 10px;
  transform: rotate(0deg);
}

.corner-bottom {
  bottom: 10px;
  right: 10px;
  transform: rotate(180deg);
}

.suit, .suit-rotate {
  position: absolute;
  font-size: 30px;
}

.suit {
  top: 0px;
  left: 75px;
  color: red;
}

.suit-rotate {
  left: 74px;
  color: red;
  transform: rotate(180deg);
}

.custom-text {
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-size: 24px;
  color: black;
}

.custom-text span {
  display: block;
}

.dashed-border {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  border: 2px dashed #d3d3d3;
  border-radius: 8px;
}


/* General Container for Room */
.room-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
}

/* Left Section (Vote Area) */
.vote-section {
  flex: 2;  /* Take up most of the width */
  padding: 20px;
}

/* Right Section (Participants Panel) */
.participants-panel {
  flex: 1;  /* Take up less width */
  background-color: #f7f7f7;
  padding: 20px;
  border-left: 2px solid #ccc;
  height: 100%;
  overflow-y: auto;
}

/* Participants and Votes List */
.participants-panel ul {
  list-style: none;
  padding: 0;
}

.participants-panel li {
  margin: 10px 0;
  background-color: #e9e9e9;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
}

/* Responsive Design */
@media (max-width: 600px) {
  .room-container {
      flex-direction: column;
  }

  .participants-panel {
      margin-top: 20px;
      border-left: none;
      border-top: 2px solid #ccc;
  }
}

/* Button styling */
.quit-button {
  background-color: #ff4d4d; /* Red background */
  color: white; /* Text color */
  padding: 10px 20px; /* Padding around the text */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
  outline: none; /* No outline when focused */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for 3D effect */
  width: 10%;
  margin-top: 100px;
}

/* Hover effect */
.quit-button:hover {
  background-color: #e60000; /* Darker red on hover */
}

/* Active (clicked) effect */
.quit-button:active {
  animation: bounce 0.2s; /* Trigger bounce animation on click */
}

/* styles.css */
.user-story-section {
  margin-top: 20px;
}

.user-story-section h3 {
  margin-bottom: 10px;
  font-size: 1.2em;
}

.user-story-section textarea {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ccc;
  resize: none; /* Prevent resizing of textarea */
}

.user-story-section textarea:focus {
  outline: none;
  border-color: #4caf50; /* Highlighted border when focused */
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
}

.user-story-card {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}

.user-story-card h4 {
  margin-bottom: 10px;
  font-size: 1.2em;
}

.user-story-card p {
  font-size: 1em;
  color: #333;
}

/* Selected card style - change everything to black */
.card.selected-black {
  border-color: black; /* Change border to black */
  background-color: #000;
}

/* Change suits and numbers to black when selected-black */
.card.selected-black .corner-top,
.card.selected-black .corner-bottom,
.card.selected-black .suit,
.card.selected-black .suit-rotate {
  color: black;
  background-color: #000;

}

.card.selected-red {
  border-color: black; /* Change border to black */
  background-color: red;
}

/* Change suits and numbers to black when selected-red */
.card.selected-red .corner-top,
.card.selected-red .corner-bottom,
.card.selected-red .suit,
.card.selected-red .suit-rotate {
  color: black;
  background-color: red;

}